.MuiTable-root {
  background-color: #fff;
}

.MuiTableHead-root {
  background-color: #219b8e;
}

.MuiTableCell-root {
  padding: 18px;
  font-family: "Montserrat", sans-serif !important;
  border-bottom: 1px solid #ededed !important;
}

.theLabelCellStart {
  border-radius: 5px 0 0 0;
}

.theLabelCellEnd {
  border-radius: 0 5px 0 0;
}

.theContentStart {
  border-radius: 0 0 0 5px;
}

.theContentEnd {
  border-radius: 0 0 5px 0;
}

.MuiPaginationItem-root {
  color: #fff;
  margin: 0 10px !important;
  background-color: #fff !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 4px !important;
  border: 0 !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.Mui-selected {
  color: #fff !important;
  background-color: #15AAA0 !important;
  font-family: "Montserrat", sans-serif !important;
}


