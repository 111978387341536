:root {
  --primary-color1: #f9f9f9;
  --primary-color2: #219b8e;
  --primary-color3: #211d4e;
  --secondary-color1: #ededed;
  --secondary-color2: #5a567b;
  --secondary-color3: #3adebb;
  --background-gradient: linear-gradient(180deg, #e3f1fd 0%, #d7ecff 100%);
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ededed;
}

code {
  font-family: 'Montserrat', sans-serif;
}

@media (min-width: 1536px) {
  .customContainer {
    max-width: 1800px !important;
  }
}

.MuiDialog-paper {
  border-radius: 10px !important;
}

.style_toast-list__378gH {
  position: fixed;
  bottom: 20px;
  left: 50%;
  z-index: 99999 !important;
  line-height: 1.5;
  text-align: center;
  transform: translateX(-50%) translateZ(0);
}